export interface informe {
Año:String;
Consumo_Total:Number;
Consumo_Red:Number;
Ahorro:Number;
Autoconsumo_Prc:String;
Generacion:Number;
Inyeccion:Number;
Exceso_Prc:String;
years:Object;
  }
  
export interface informeAcometida {
valor:String;
EnergiaActiva:String;
Reactiva:String;
Inductiva:String;
Maximetro:String;
PotenciaMedia:String;
years:Object;
  }
  
export interface informeSolar {
valor:String;
Generacion:String;
Reactiva:String;
Inductiva:String;
Maximetro:String;
PotenciaMedia:String;
years:Object;
  }
  