import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/servicios/database/database.service';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FsessionService } from 'src/app/servicios/session/fsession.service';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-perfil-usario',
  templateUrl: './perfil-usario.component.html',
  styleUrls: ['./perfil-usario.component.scss']
})
export class PerfilUsarioComponent implements OnInit, OnDestroy {

  animacion = false;
  cargando = false;
  modificar = 'true';
  titulo ='';
  mensajeUsuario = ' ';
  stateCtrlUsuario = new FormControl();
  datosUsuario: Usuario[];
  datosOriginales: any;
  keyTraslate: string[] = ['verModUsuario','usuario','sesionCaducada','nombreMostrar','correo','verificarlossiguientesdatos'];
  variables;
  
  constructor(private traslate: TranslateService,private fse: FsessionService, public dialog: MatDialog, private databaseService: DatabaseService, private authService: MsalService,
    public router: Router, public dialogRef: MatDialogRef<PerfilUsarioComponent>,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(MAT_DIALOG_DATA) public data: dialogUser) {

      this.traslate.get(this.keyTraslate).subscribe(res=>{
        this.variables=res;
        this.titulo = this.variables.verModUsuario;
        this.mensajeUsuario = this.variables.usuario
    })   
    this.fse.getSubscribeLang().subscribe(()=>{
      this.traslate.get(this.keyTraslate).subscribe(res=> this.variables=res);
    })
  }

  ngOnDestroy() {
    this.dialogRef.close(this.data);
  }

  ngOnInit() {
    this.getData();
    if (this.data.IdUsuario != undefined) {
      this.modifyMode();
    } 
  }

  /**
   * Funcion para modificar usuario
   */
  modifyMode() {
    let editProfileFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.editProfile.authority,
      scopes: environment.apiConfig.scopes
  };

    this.fse.agregarActualizar('defaulRoute', this.router.url);
    this.redirectAzure(editProfileFlowRequest);
  }

  /**
   * Redireccionar a la modificacion de usuario de azure
   * @param userFlowRequest parametro de validacion de autenticacion
   */
  redirectAzure(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
        this.authService.loginRedirect(userFlowRequest);
    }
  }

  /**
   * Funcion para obtener datos del usuario
   */
  getData() {
    this.databaseService.TraerDetalleUsusario().subscribe((datos: Usuario[]) => {
      if (this.data.IdUsuario == undefined) {
        this.data.IdUsuario = datos[0].IdUsuario;
        this.data.Activo = datos[0].Activo;
        this.data.Nombre = this.fse.optenerValor('dato2');
        this.data.Email = this.fse.optenerValor('dato48');
        this.data.Usuario = datos[0].Usuario;
      }
      this.datosUsuario = datos;

    })
  }

  /**
   * Cerrar dialogo
   */
  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

}
export interface dialogUser {
  IdUsuario: String,
  Activo: any,
  Nombre: any,
  Email: any,
  Usuario: any,
  roles: any,
  modoModificar: boolean
}