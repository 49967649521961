import { Injectable } from '@angular/core';
import { configuracion } from 'src/configuracion';

@Injectable({
  providedIn: 'root'
})
export class SeguimientoService {

        private vS= configuracion.servidor_Stream;

        private rTcM=`${configuracion.servidor_api}/TraerMatriculasBd2`;
        private rIc=`${configuracion.servidor_api}/InfoCamaras`; 
        private rIs=`${configuracion.servidor_api}/InfoSoportes`;
        private rIcM=`${configuracion.servidor_api}/TraerCallesMunicipio`;
        private rIr=`${configuracion.servidor_api}/TraerRouters`;

        private rV=`${this.vS}/api/stream`;
        private rVst=`${this.vS}/api/stream/stop`;

        private rU=`${configuracion.servidor_api}/usuarios`;
        private rIM=`${configuracion.servidor_api}/InfoMunicipios`;
        private rTs=`${configuracion.servidor_api}/TraerTiposSoporte`;
        private rTc=`${configuracion.servidor_api}/TraerTiposCamara`; 
        private rAb=`${configuracion.servidor_api}/AlertaBusqueda`;

        private rUg=`${configuracion.servidor_api}/GuardarUsuario`;
        private rUm=`${configuracion.servidor_api}/ModificarUsuario`;
        private rUcC=`${configuracion.servidor_api}/ClaveUsuario`;

        private rMg=`${configuracion.servidor_api}/GuardarMunicipio`;
        private rMm=`${configuracion.servidor_api}/ModificarMunicipio`;

        private rSm=`${configuracion.servidor_api}/ModificarTipoSoporte`;
        private rSe=`${configuracion.servidor_api}/EliminarTipoSoporte`;
        private rSg=`${configuracion.servidor_api}/GuardarSoporte`; 

        private rCg=`${configuracion.servidor_api}/GuardarCamara`;
        private rCm=`${configuracion.servidor_api}/ModificarCamara`;

        private rcg=`${configuracion.servidor_api}/AlmacenarCalle`;
        private rcm=`${configuracion.servidor_api}/ModificarCalle`;

       
        private rDa=`${configuracion.servidor_api}/ActualizarEstadoPendienteDes`;

        private rBa =`${configuracion.servidor_api}/BuquedaAvanzada`;

        private rAu=`${configuracion.servidor_api}/actualizarUsuario`

        private rAaU=`${configuracion.servidor_api}/ActualizarAsignacionUsuario`;

        private rAtS=`${configuracion.servidor_api}/AgregarTipoSoporte`;

        private rMs=`${configuracion.servidor_api}/ModificarSoporte`;

        private rAtC=`${configuracion.servidor_api}/AgregarTipoCamara`;

        private rMtC=`${configuracion.servidor_api}/ModificarTipoCamara`;

        private rAr=`${configuracion.servidor_api}/AgregarRouter`;

        private rMr=`${configuracion.servidor_api}/ModificarRouter`;

        private rDp=`${configuracion.servidor_api}/DescargasPendientes`

        private rAdP=`${configuracion.servidor_api}/AgregarDescargaPendiente`;

        private rMm2=`${configuracion.servidor_api}/ModificarMatricula`;

        private rAdE=`${configuracion.servidor_api}/AgregarDescargaPendienteEvento`;

        private rAeD=`${configuracion.servidor_api}/ActualizarEstadoPendienteDesc`;

        private rEd=`${configuracion.servidor_api}/EliminarPendienteDesc`
        
        public contenedorRutas:string[]=[this.rAu, this.rAaU, this.rUg, this.rUm, this.rMg, this.rMm, this.rAtS, this.rSm, this.rSe, this.rSg, this.rMs, this.rCg, this.rCm, this.rcg, this.rcm, this.rAtC, this.rMtC, this.rAr, this.rMr, this.rAdP, this.rMm2, this.rAdE, this.rAeD, this.rEd]

        public contenedorRutasLlamadas :string[]=[];


  constructor() { }
}
