export type Usuario = {
    IdUsuario:String,
    Nombre:String,
    Email:String,
    Usuario:String,
    FechaAlta:String,
    Activo:String,
    Rol:String,
    id_rol:Number,
    tiempo_sesion:String,
    azure_oid?:string,
    NombresMunicipios?: string,
    superAdmin?: boolean
    CantidadMunicipios?: number
}

export interface UsuariosAdministracion{
    Nombre: string,
    RolNombre: string
    Activo: boolean
}

export interface UsuarioTabla {
    IdUsuario:String,
    Nombre:String,
    Seleccionado:Boolean
}
export interface UsuarioTabla2 {
    IdUsuario:String,
    Nombre:String
}

export interface UsuarioChangePassword {
    IdUsuario: String;
    Clave: String;
}

export interface UsersByMunicipality {
    id: number;
    name: boolean;
    active: string;
    rol: string;
    totalRegistros: number;
}

export interface MunicipalityConfigurationByUser {
    idUsuario: number;
    idMunicipio: number; 
    configuracion: string; 
    fecha_alta: string;
    noplate: boolean;
}