import { MatTableDataSource } from '@angular/material/table';
import { UsuarioTabla2 } from './usuario';

export interface Municipio {
  IdMunicipio: number;
  Nombre: string;
  EsDefecto: boolean;
  latitud: string;
  longitud: string;
  Usuario: string;
  Clave: string;
  zona_horaria: string;
  modulos: string;
  permisos: string;
  utc_offset: string;
  ParametrosDegradado?: string;
}

export interface DatosUsuario {
    dato2: string
    Activo: boolean
    dato3: string
    Configuracion: string
    CantCamaras: number
    CantDescargas: number
    ManualUsuario: string
    nuevo: boolean
    fechaClave: string
    munDefault: number
    lat: string
    lng: string
    fecha_terminos_aceptados: string,
    accessToken: string,
    configuracion_matr: string
    busquedaMultimunicipio: boolean
    ValoresDegradadoMapa: string
}

export interface MunicipioTabla {
  IdMunicipio: number;
  Nombre: string;
  Defecto?: boolean;
}


export interface ParametrosMunicipio {
  ParametrosDegradado: string
}

export interface MunicipioName{
  NombresMunicipios : any;
}

export interface MunicipioG {
  IdMunicipio:any,
  Nombre:any,
  Direccion:any,
  Provincia:any,
  CodPostal:any,
  Poblacion:any,
  Contacto:any,
  Contacto2?:any,
  Email:any,
  Telefono:any,
  Telefono2?:any,
  Contacto3?:any,
  Telefono3?:any,
  Fechainstalacion:any,
  FechaInstalacionAcometida:any,
  CUPS:any,
  IdTarifa:any,
  Latitud:any,
  Longitud:any,
  Eliminado:any,
  listaAgregar: { IdUsuario: String, Nombre: String }[],
  listaEliminar: { IdUsuario: String, Nombre: String }[],
  UsuariosTmp: MatTableDataSource<UsuarioTabla2>
  modoModificar: boolean,
  usuariosAgregar: String,
  usuariosEliminar: String,
  estadoDialogo:String,
  zona_horaria: String
  modulos?: number[];
}

export interface InstalacionG {

  IdInstalacion: any,
  Nombre: any,
  IdGrupo: any,
  IdAcometida: any,
  IdPaneles: any,
  EsPositivo: any,
  TipoInstalacion: any,
  OpeMantenimiento: any,
  OpeAcometida: any,
  OpeInstalacion: any,
  Cliente: any,
  DescPaneles: any,
  DescInversores: any,
  PotPico: any,
  PotNominal: any,
  Direccion: any,
  Provincia: any,
  CodPostal: any,
  Poblacion: any,
  Contacto: any,
  Contacto2?: any,
  Email: any,
  Telefono: any,
  Telefono2: any,
  Fechainstalacion: Date,
  listaAgregar: { IdUsuario: String, Nombre: String }[],
  listaEliminar: { IdUsuario: String, Nombre: String }[],
  UsuariosTmp: MatTableDataSource<UsuarioTabla2>,
  modoModificar: boolean,
  usuariosAgregar: String,
  usuariosEliminar: String,
  estadoDialogo:String
}

export interface InstalacionTabla {
  IdInstalacion: number;
  Nombre: string;
}

export interface nombreUserRol {
  nombre: string;
  rol: string;
}

export interface contactos {
  Nombre: string;
  IdMunicipio: number;
  nombre_contacto: string;
  numero_contacto: string;
  activo: boolean;
  alarma_telemetria: boolean;
  alarma_infracciones: boolean;
  alarma_lista_blanca: boolean;
  alarma_lista_negra: boolean;
}