import { Component, ChangeDetectorRef } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { ErrorsB2C } from 'src/app/models';
import { FsessionService } from 'src/app/servicios/session/fsession.service';

@Component({
  selector: 'app-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss']
})
export class LoginFailedComponent {

  ErrorMessage: string;
  titleMessage: string;
  error_B2C_User_Cancellation = ErrorsB2C.B2C_User_Cancellation;

  constructor( 
    private fse: FsessionService, 
    private changeDetectorRef: ChangeDetectorRef, 
    public authService: MsalService
  ) 
  {
    this.error();
  }

  /**
   * Funcion para el manejo de errores
   */
  error() {

    let error = this.fse.optenerValor('errorB2C');
    /**
     * solo si el error es diferente al de "correo incorrecto al intentar actualizar password", 
     * validamos la sesión
     */
    if ( error !== ErrorsB2C['B2C-User-Email-Invalid'] ) this.validateSession();
    let restorePass =  JSON.parse(this.fse.optenerValor('restorePass'));

    if(error){
      this.titleMessage = 'ERROR';
      switch (error){
        case ErrorsB2C.B2C_User_Cancellation:
          this.ErrorMessage = error;
          this.restart();
        break
  
        case ErrorsB2C['B2C-User-No-Oid']: 
          this.ErrorMessage = "B2C-User-No-Oid";
        break;
  
        case ErrorsB2C['B2C-User-No-Permission']: 
          this.ErrorMessage = "B2C-User-No-Permission";
        break;

        case ErrorsB2C['B2C-Server-Failed']:
          this.ErrorMessage = "B2C-Server-Failed";
        break;

        case ErrorsB2C['B2C-User-Email-Invalid']:
          this.ErrorMessage = "B2C-User-Email-Invalid";
        break;
  
        default:
          this.validateSession();
          if(restorePass){
            this.titleMessage = 'operacionExitosa';
            this.ErrorMessage = 'restaurarConstraseña';
            setTimeout(() => this.fse.deleteVariable('restorePass'), 1000);
          }else{
            this.ErrorMessage = "errorAlValidarUsuario";
          }
        break;
      }
    }else{

      setTimeout(() => {
        this.titleMessage = 'edicion_usuario';
        this.ErrorMessage = 'fail_edicion_usuario';

        this.restart();
        this.changeDetectorRef.detectChanges(); 
        return;
      }, 1000);
    }

  }

  /**
   * Ir a la url por defecto
   */
  restart(){
    const error = this.fse.optenerValor('errorB2C');
    if ( error === ErrorsB2C['B2C-User-Email-Invalid'] ) this.fse.deleteVariable('errorB2C');
    window.location.replace('/');
  }

  /**
   * Valida si tiene un cuenta activa
   * @returns retorna a la primera ruta que tenga permiso
   */
  validateSession(){
    if ( this.authService.instance.getAllAccounts().length > 0 ) {
      this.restart();
      this.ErrorMessage = ErrorsB2C.B2C_User_Cancellation;
      return;
    }
  }

}
