import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FsessionService } from "src/app/servicios/session/fsession.service";
import { MenuHeader } from "../header/header.component";

@Component({
  templateUrl: "./module-redirect.component.html",
  styleUrls: ["./module-redirect.component.scss"],
})
export class ModuleRedirectComponent implements OnInit {
  headers: MenuHeader[];

  constructor(private fse: FsessionService, private router: Router) {
    this.headers = this.fse.getHeaders();
  }

  ngOnInit(): void {
    const firstAvailableRoute = this.headers[0].routerLink;
    if(this.headers[0] && firstAvailableRoute) {
      this.router.navigate([firstAvailableRoute]);
    }
  }
}
