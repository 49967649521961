import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { DatabaseService } from 'src/app/servicios/database/database.service';
import { PasswordService } from 'src/app/servicios/password/password.service';
import { MessageService } from '../../servicios/message/message.service';
import { LoaderService } from '../../component/loader-spinner/loader.service';

@Component({
  selector: 'app-modificar-usuario',
  templateUrl: './modificar-usuario.component.html',
  styleUrls: ['./modificar-usuario.component.scss']
})
export class ModificarUsuarioComponent implements OnInit, OnDestroy {

  newPasswordForm:FormGroup;
  errorAlertMessage: string;
  successAlertMessage: string;
  passwordLevel = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {[key: string]: string},
    public router: Router, 
    public databaseService: DatabaseService,
    public dialogRef: MatDialogRef<ModificarUsuarioComponent>,
    public _passwordService: PasswordService,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    private _loaderService: LoaderService
    ) {

      this.newPasswordForm = new FormGroup({
        newPassword: new FormControl('', Validators.required),
        passwordConfirm: new FormControl('', Validators.required)
      },{
        validators: this._passwordService.passwordMatchValidator
      });

  }
  
  ngOnInit() {

    /**
    * Traduce los mensajes que se utilizaran en las alertas respectivas
    */  
     this._translateService.get( [ 'mensaje_confirmacion', 'error'] ).subscribe( ({ mensaje_confirmacion, error }) => {
      this.successAlertMessage = mensaje_confirmacion;
      this.errorAlertMessage = error; 
    });

    /**
     * Establece el estado del 'mat-dialog'
     */
    this.data.estadoDialogo = '';

    /**
     * Detecta el cambio de valor del campo 'newPassword' el cual el usuario esta modificando 
     * y actualiza el 'passwordLevel'
     */
    this.newPasswordForm.controls['newPassword'].valueChanges
      .pipe(debounceTime(100))
      .subscribe(
        value => {
          this.passwordLevel = this._passwordService.getPasswordLevel(value);
        }
      );

  }

  /**
   * Cambia el estado del 'mat-dialog' y lo cierra enviando la 'data' antes de que destruya el componente 
   */
  ngOnDestroy(): void {

    if (this.data.estadoDialogo.trim() == '') {
      this.data.estadoDialogo = 'DESTRUIDA';
    }
    this.dialogRef.close(this.data);

  }

  /**
   * Cierra el 'mat-dialog' y actualiza estado del 'mat-dialog'
   */
   closeDialog(): void {
    this.data.estadoDialogo = 'CERRAR';
    this.dialogRef.close(this.data);
  }

  /**
   * Retorna el campo del formulario llamdo 'newPassword'
   * @returns AbstractControl
   */
  get newPassword() {
    return this.newPasswordForm.controls['newPassword'];
  }

  /**
   * Llama al metodo 'checkRule' que validara la nueva contraseña
   * @param rule regla para validar
   * @returns boolean
   */
  checkRule(rule: string) {
    const { newPassword } = this.newPasswordForm.value;
    return this._passwordService.checkRule(rule, newPassword);
  }

  /**
   * Realiza peticion http a la base de datos para actualizar la contraseña de un usuario
   */
  Actualizar() {

    const { newPassword, passwordConfirm } = this.newPasswordForm.value;
    let datosCambio = {
      Clave: newPassword,
      Clave2: passwordConfirm,
      IdUsuario: this.data.IdUsuario,
      estadoDialogo: ''
    };

    if (this.newPasswordForm.valid) {

      this._loaderService.showSpinner(true);
        this.databaseService.ClaveUsuario( { IdUsuario: datosCambio.IdUsuario, Clave: datosCambio.Clave } )
        .subscribe({
          next: (data) => {
  
            this._loaderService.showSpinner(false);
            if (data.mensaje.toString() == 'CLAVE ACTUALIZADA') {
                this._messageService.SwalMessage( 'success', this.successAlertMessage, false, 2000 );
                datosCambio.estadoDialogo = 'ALMACENAR';
                this.dialogRef.close(datosCambio);
            } else {
                this._messageService.SwalMessage('error', this.successAlertMessage, false, 2000 );
            }
  
          },
          error: (err) => {

            this._loaderService.showSpinner(false);
            this._translateService.get(err.error.traslateError).subscribe(message => this._messageService.SwalMessage('error', message));
  
          }
        });

    }

  }

}