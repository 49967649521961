import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MsalRedirectComponentGuard implements CanActivate {

    constructor(
        private router: Router
    ){}

    /**
     * Este guardia solo se debe utilizar en la ruta "/auth"
     * Permite redirigir a la ruta principal, esto para evitar el error de la pantalla en blanco en esta ruta 
     */
    canActivate(): boolean {
        this.router.navigateByUrl('/');
        return true;
    }
}