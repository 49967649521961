import { StatusImage } from "./eventos-cam.model";

export interface LicensePlateDetails {
    id_evento: number;
    id_camara: string;
    camara: string;
    matricula: string;
    Fecha_imagen: string;
    Fecha_imagen_utc: string;
    IdSoporte: number;
    Ip: string;
    Latitud: string;
    Longitud: string;
    Soporte: string;
    deteccion: string;
    calle: string;
    estado_imagen: StatusImage;
    fechaantes: string;
    fechadespues20: string;
    fechadespues70: string;
    fechaBusquedaantes: string;
    fechaBusquedadespues20: string;
    fechaBusquedadespues70: string;
    ahorro_consumo: number;
    url?: string;
}

export interface LicensePlateInformation {
    tipo: string;
    lista: string;
    NUM_LISTAS: number;
    id_camara: string;
    id_eventoscam: number;
    estado_imagen: number;
    ahorro_consumo: number;
    matricula: string;
    matricula_2: StatusImage;
    Fecha_imagen: string;
    Fecha_imagen_utc: string;
    deteccion: string;
    Camara: string;
    Soporte: string;
    Latitud: string;
    Longitud: string;
    IdSoporte: number;
    Calle: string;
    IdDistintivoMedioambiental: number;
    Ip: string;
    fechaantes: string;
    fechadespues20: string;
    fechadespues70: string;
    fechaBusquedaantes: string;
    fechaBusquedadespues20: string;
    fechaBusquedadespues70: string;
    color: string;
    marca: string;
    velocidad: string;
    tipo_infraccion?: any;
    fecha_envio_cgi?: Date;
    archivo_ZIP?: any;
    fecha_rojo_ini?: any;
    fecha_rojo_fin?: any;
    fecha_rojo_evento?: any;
    falso_rojo?: any;
    sin_OCR?: any;
    url?: string;
    archivo?: string;
}
