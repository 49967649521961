import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { configuracion } from "../../../configuracion";
import { Municipio } from 'src/app/models';
@Injectable({
  providedIn: 'root'
})
export class FsessionService {
  permission;
  headers: any;
  private _appState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _currentCity: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  _currentLang: BehaviorSubject<string> = new BehaviorSubject<string>('');
  _userCities: BehaviorSubject<Municipio[]> = new BehaviorSubject<Municipio[]>([]);
  constructor() { }
  agregarActualizar(variable: string, valor: string) {
    var datos = this.capturarSession();
    datos[variable] = CryptoJS.AES.encrypt(valor, configuracion.claveEncriptacion).toString();
    sessionStorage.setItem('session', CryptoJS.AES.encrypt(JSON.stringify(datos), configuracion.claveEncriptacion));
  }

  deleteVariable(variable: string) {
    var datos = this.capturarSession();
    delete datos[variable];
    sessionStorage.setItem('session', CryptoJS.AES.encrypt(JSON.stringify(datos), configuracion.claveEncriptacion));
  }

  getAppState(){
    return this._appState.asObservable()
  }

  setAppState(state:boolean){
    this._appState.next(state);
  }

  capturarSession() {
    var datos = {};
    if (sessionStorage.getItem('session')) {
      datos = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('session'), configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8));
    }
    if( sessionStorage.getItem('permSession')){
      this.permission = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('permSession'), configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8));
    }
    if( sessionStorage.getItem('headers')){
      this.headers = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('headers'), configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8));
    }
    return datos;
  }

  optenerValor(variable: any) {
    var datos = this.capturarSession();
    if (datos[variable]==undefined) {
      return false;
    }else{      
    return CryptoJS.AES.decrypt(datos[variable], configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);
    }
  }

  closeSession(){
    sessionStorage.clear();
  }

  setPermission(permission){
    this.permission = permission;
    sessionStorage.setItem('permSession', CryptoJS.AES.encrypt(JSON.stringify(this.permission), configuracion.claveEncriptacion))
  }


  getPermission(){
    if( this.permission == undefined) this.capturarSession()
    return this.permission;
  }

  getHeaders(){
    if( this.headers == undefined) this.capturarSession()
    return this.headers;
  }

  setCity(city){
    this.agregarActualizar('dato4',city);
    this._currentCity.next(city);
  }

  getSubscribeCity(){
    return this._currentCity.asObservable();
  }

  setLanguage(lang){
    this.agregarActualizar('language',lang);
    localStorage.setItem('ui_locales',lang);
    this._currentLang.next(lang);
  }

  getSubscribeLang(){
    return this._currentLang.asObservable();
  }

  setUserCities(cities: Municipio[]) {
    this._userCities.next(cities)
  }

  getUserCities(): Observable<Municipio[]> {
    return this._userCities.asObservable();
  }

}
