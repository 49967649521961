import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FsessionService } from 'src/app/servicios/session/fsession.service';

@Directive({
  selector: '[IsAllowed]'
})
export class IsAllowedDirective {
  constructor( private fse: FsessionService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }


  @Input() set IsAllowed(condition) {
    if(this.fse.optenerValor('dato43')[condition] !== '1'){
      this.viewContainer.clear();
    }else{
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
