export interface Group {
  municipio: string;
  nombreGrupo: string;
  idGrupo: number;
  idMunicipio: number;
  totalRegistros?: number;
  configuracion?: any;
}

export interface CamerasFilteredByGroup {
  camsId: CamsId[];
  settings: string;
  idGrupo: number;
}

export interface CameraSelectedInCheckbox {
  id: number; 
  id_camara: string;
  nombre: string;
}

interface CamsId {
  id_camara: string;
  id: number;
}