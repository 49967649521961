import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class PasswordService {
    private strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    private mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

    constructor() { }

    /**
     * Determina el nivel de seguridad del 'password' recibido
     * @param password contraseña
     * @returns un numero que representa el nivel de seguridad del 'password'
     */
    getPasswordLevel(password: string): number {
        if (this.strongPassword.test(password)) {
            return 100;
        }
        if (this.mediumPassword.test(password)) {
            return 50;
        }
        return 0;
    }

    /**
     * Valida si la contraseña cumple con las reglas establecidas 
     * @param rule regla para validar
     * @param password contraseña a validar
     * @returns boolean
     */
    checkRule(rule: string, password: string) {
        switch (rule) {
            case 'min-chars':
                return new RegExp('(?=.{8,})').test(password);
            case 'uppercase':
                return new RegExp('(?=.*[A-Z])').test(password);
            case 'lowercase':
                return new RegExp('(?=.*[a-z])').test(password);
            case 'digit':
                return new RegExp('(?=.*[0-9])').test(password);
            case 'special-char':
                return new RegExp('([^A-Za-z0-9])').test(password);
            default:
                return false;

        }
    }
    
    /**
     * Definimos formato para contraseñas se llama como validacion en el campo de un formulario
     * @returns un error de tipo (ValidationErrors) o null en caso de pasar la validacion
     */
     formatPassword() {
        return (control: AbstractControl): ValidationErrors | null => {
          const value = control.value;
          if (!value) return null;
      
          const hasUpperCase = /[A-Z]+/.test(value);
          const hasLowerCase = /[a-z]+/.test(value);
          const hasNumeric = /[0-9]+/.test(value);
          const hasSpecialChar = /[^A-Za-z0-9]/.test(value);
          const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar && value.length >= 8;
          return !passwordValid ? { passwordStrength: true } : null;
        };
      }

    /**
     * Funcion para comparar dos contraseñas es necesario que los campos se llamen 'newPassword' y 'passwordConfirm'
     * @param control AbstractControl clase base de (FormControl, FormGroup o FormArray) 
     * @returns un null o el error llamado 'mismatch'
     */
    passwordMatchValidator(control: AbstractControl) {
        return control.get('newPassword').value === control.get('passwordConfirm').value ? null : { 'misMatch': true };
    }
    
}