import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';

import { ValidateRolesDirective } from './directives/validateRoles/validate-roles.directive';
import { GenericTableComponent } from './shared/components/generic-table/generic-table.component';
import { configuracion } from 'src/configuracion';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, configuracion.translations_URL);
}

@NgModule({
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatTableModule,
        MatTooltipModule,
        MatIconModule,
        MatSortModule,
        MatButtonModule,
        TranslateModule.forChild({
            extend: true,
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ],
    declarations: [
        ValidateRolesDirective,
        GenericTableComponent
    ],
    exports: [
        ValidateRolesDirective,
        GenericTableComponent
    ],
})
export class UtilsModule {}