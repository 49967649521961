import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as CryptoJS from 'crypto-js';
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from "@ngx-translate/core";

import packageJson from "../../../../package.json";
import { environment } from "src/environments/environment";
import { LoaderService } from "../loader-spinner/loader.service";
import { MessageService } from "src/app/servicios/message/message.service";
import { PasswordService } from '../../servicios/password/password.service';
import { configuracion } from "../../../configuracion";
import { DatabaseService } from "../../servicios/database/database.service";
import { FsessionService } from "../../servicios/session/fsession.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

  @ViewChild(SwalComponent) swal: SwalComponent;

  Form: FormGroup;
  passwordForm: FormGroup;
  traducciones: any;
  error: boolean;
  cargando: boolean = false;
  imagen_fondo = '../../../assets/imagenes/background.png';
  primeraRuta: any;
  activeLang = "en";
  version: string = packageJson.version;
  isNewUser: boolean = false;
  showForms: boolean = true;
  dontShowAgain: boolean = false;
  swalWidth = '550px';
  swalConfirmButtonAndIconColor = '#294ADB';

  constructor(
    private translate: TranslateService,
    private fse: FsessionService,
    private databaseService: DatabaseService,
    private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private _passwordService: PasswordService,
    public readonly swalTargets: SwalPortalTargets,
  ) {

    this.databaseService.asignarIpPublica();

    /**
     * Formulario de inicio de sesion
     */
     this.Form = new FormGroup({
      language: new FormControl('', Validators.required),
      user: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });

    /**
     * Formulario de cambio de contraseña
     */
    this.passwordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, this._passwordService.formatPassword()]),
      passwordConfirm: new FormControl('', Validators.required),
    }, {
     validators: this._passwordService.passwordMatchValidator
    });

  }

  ngOnInit(): void {

    const browserLang = this.translate.getBrowserLang();
    this.Form.controls["language"].setValue(browserLang);
    this.changeLanguage(browserLang);

    /**
    * Traduce los mensajes que se utilizaran en las alertas respectivas
    */ 
    this.translate.get(['error']).subscribe( variables => this.traducciones = variables );

  }
  
  /**
   * Valida las credenciales del usuario y permite el acceso al sistema
   */
  onSubmit() {

    this.cargando = true;
    let user = CryptoJS.AES.encrypt((this.Form.controls['user'].value).toString(), configuracion.claveEncriptacion).toString();
    let password = CryptoJS.AES.encrypt((this.Form.controls['password'].value).toString(), configuracion.claveEncriptacion).toString();
    
    this.databaseService.supplierLogin(user, password).subscribe({

      next: async ( user: any ) => {
        
        await this.saveSession(user);
        let diffDay = (new Date().getTime() - new Date(user.user.fechaClave).getTime()) / 86400000;

        /**
         * Valida si el usuario es nuevo o la fecha de vencimiento de actualizar la contraseña expiro
         */
        if ( user.user.nuevo == 1 || Math.round(diffDay) > environment.expiration_password ) {
            this.error = false;
            this.cargando = false;
            this.loaderService.showSpinner(false);
            this.isNewUser = true;

        } else if (this.fse.optenerValor("dato40")) {
            const fecha_terminos_aceptados = user.user.fecha_terminos_aceptados;
            if(!fecha_terminos_aceptados) {
              this.showForms = false;
              this.swal.fire();
            } else {
              this.router.navigate(["traffic"]);
            }

        }

      },
      error: ({ error }) => {

        if(error.alert) {
          this.messageService.dialogMessage(
            'error', this.traducciones.error, {text: this.traducciones[error.message]}
          );
        }

        this.cargando = false;
        this.loaderService.showSpinner(false);
        this.error = true;

      }

    });

  }

  /**
   * Funcion que confirma los terminos del servicio por parte del usuario
   */
  confirmarTerminos() {

    this.loaderService.showSpinner(true);
    this.databaseService.updateTerms()
    .subscribe(() => {
      this.router.navigate(["traffic"]);
    });

  }

  /**
   * Configura el sistema con el idioma solicitado( Español, Frances o Ingles)
   * @param language Codigo del idioma
   */
  changeLanguage(language: string) {
    this.translate.use(language);
    this.fse.agregarActualizar("language", language);
  }
  
  /**
   * Actualiza la contraseña del usuario
   */
  updatePassword() {

    if (this.passwordForm.valid) {

      let data = {
        IdUsuario: this.fse.optenerValor("idusuario"),
        newPassword: this.passwordForm.get('newPassword').value,
        passwordConfirm: this.passwordForm.get('passwordConfirm').value
      };

      this.cargando = true;
      this.databaseService.ClaveUsuario({ IdUsuario: data.IdUsuario, Clave: data.newPassword }).subscribe({
        next: () => {
          this.cargando = false;
          this.router.navigate(["traffic"]);
        },
        error: (err) => {
          this.cargando = false;
          if(err!= null) this.messageService.SwalMessage( 'error', this.traducciones['error'], false, 2000 );  
        }
      });

    } else {
      this.error = true;
    }

  }

  /**
   * Retorna el campo del formulario llamdo 'newPassword'
   * @returns AbstractControl
   */
   get newPassword() {
    return this.passwordForm.controls['newPassword'];
  }

  /**
   * Llama al metodo 'checkRule' que validara la nueva contraseña
   * @param rule regla para validar
   * @returns boolean
   */
   checkRule( rule: string ) {
    const { newPassword } = this.passwordForm.value;
    return this._passwordService.checkRule(rule, newPassword);
  }

  /**
   * Guarda la session actual
   * @param usuario Informacion de la session
   */
  async saveSession(usuario: any): Promise<boolean> {

    this.fse.agregarActualizar('dato1', usuario.dato1);

    var token = this.fse.optenerValor('dato1');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    var rol = CryptoJS.AES.decrypt(decodedToken.fvru, configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);
    var permiso = CryptoJS.AES.decrypt(decodedToken.fvpu, configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);
    var modulos = CryptoJS.AES.decrypt(decodedToken.fvmod, configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);
    var id = CryptoJS.AES.decrypt(decodedToken.fviu, configuracion.claveEncriptacion).toString(CryptoJS.enc.Utf8);

    this.fse.agregarActualizar('configuracion', usuario.user.Configuracion);
    this.fse.agregarActualizar('paquetes', modulos.toString());
    this.fse.agregarActualizar('dato2', usuario.user.dato2.toString());
    this.fse.agregarActualizar('dato3', usuario.user.dato3 + '');
    this.fse.agregarActualizar('dato44',usuario.dato44 );
    this.fse.agregarActualizar('dato45', usuario.dato45);
    this.fse.agregarActualizar('dato46', decodedToken.fviu);
    this.fse.agregarActualizar('dato47', decodedToken.fvru);
    this.fse.agregarActualizar('dato38', ''+usuario.user.CantCamaras);
    this.fse.agregarActualizar('dato39',''+ usuario.user.CantDescargas);
    this.fse.agregarActualizar('manualUsuario',''+ usuario.user.ManualUsuario);
    this.fse.agregarActualizar('idusuario', id);
    this.fse.agregarActualizar('dato40', rol);
    this.fse.agregarActualizar('dato43', permiso);
    this.fse.agregarActualizar('dato42', usuario.user.nuevo +'');
    this.fse.agregarActualizar('dato4', usuario.user.munDefault.toString());
    this.fse.agregarActualizar('dato7', usuario.user.lat);
    this.fse.agregarActualizar('dato8', usuario.user.lng);
    this.fse.agregarActualizar('perm', usuario.user.perm);
    return true;

  }

}