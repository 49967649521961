export interface CamaraG {
  Id: number,
  id_camara: string,
  nombre: string,
  IdSoporte: string,
  modelo: string,
  marca: string,
  Ip: string,
  calle: string,
  Soporte: string,
  Altura: string,
  GradosOrientacion: any,
  Entorno: string,
  Titulo: string,
  posicion: string,
  estadoDialogo: string,
  Modificar: string,
  DatosOriginales: any,
  IdTipoCamara: any,
  tipoDeteccion: any,
  TipoCamara: any,
  Activa: number,
  Latitud: string,
  Longitud: string,
  alcanceCoordenadas: string;
  parking: string;
  ahorro_consumo: number;
  totalRegistros?: number;
  peatones_lineas?: boolean;
  Nombre_zona_lineas?: string;
}

export interface DialogoReproductorModel {
  id_camara: string,
  titulo: string,
  ip: string,
  camaras: any,
  Posicion: any,
  TipoCamara: any
}

export interface CamaraValida {
  Id: string,
  Ip: string
}

export interface CamaraValida2 {
  estado: string,
  mensaje: string
}

export interface ListImagenes {
  archivo: string,
  Fecha_imagen: any,
  Hora_procesado: any
}

export interface CameraGroup {
  id: number;
  id_camara: string;
  nombre: string;
  ip: string;
  TipoCamara: string;
  IdSoporte: number;
  calle: string;
  Municipio?: string;
  IdMunicipio?: string; 
  Descripcion?: string;
}

export interface CameraVisor {
  id: number;
  id_camara: string;
  nombre: string;
  FechaImagenFormato?: string;
  Fecha_imagen?: string;
  Fecha_imagen_utc?: string;
  TipoCamara?: number;
  archivo?: string;
  estado?: string;
  fechaBusquedaantes?: string;
  fechaBusquedadespues20?: string;
  fechaBusquedadespues70?: string;
  fechaantes?: string;
  fechadespues20?: string;
  fechadespues70?: string;
  id_eventoscam?: number;
  ip?: string;
  url?: string;
  isStream?: boolean;
  stopCam?: boolean;
  activado?: string;
}

export interface CameraAccountSelectedPerson {
  id: number;
  id_camera: string;
  name: string;
  checked: boolean;
}

export interface InfoCameraAndSupportLocation {
  id: number;
  id_camara: string;
  nombre: string;
  ip: string;
  IdSoporte: number;
  calle: string;
  Descripcion: string;
  Latitud: string;
  Longitud: string;
}

