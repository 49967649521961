import { Injectable } from '@angular/core';
import { Router, CanActivate} from '@angular/router';
import { FsessionService } from "../servicios/session/fsession.service";
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private fse:FsessionService,private router: Router) { }

  canActivate() {

    if (this.fse.optenerValor('dato1')) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
