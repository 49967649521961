import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ListenDOMChange {

    /** propiedad que es emitida cuando se modifica el 'resize' de la ventana - permite subscripcion  */
    isMobile: BehaviorSubject<boolean> = new BehaviorSubject( window.innerWidth <= 768 );

    /** Propiedad que contendra el renderizado personalizado del elemento DOM */
    renderer: Renderer2;

    constructor(
        private rendererFactory2: RendererFactory2
    ){
        this.renderer = this.rendererFactory2.createRenderer( null, null );
        this.renderer.listen('window', 'resize', () => {
            this.isMobile.next( window.innerWidth <= 768 );
        });
    }

}