export interface VehiculoListaInteres {
    activo: boolean,
    color: string,
    id: number
    id_lista: number
    matricula: string
    modelo: string
    observaciones: string
}

export interface VehiculoListaInteresExtends {
  totalRegistros: number;
  id: number;
  fecha_creacion: string;
  fecha_modificacion: string | null;
  descripcion: string;
  notificacion_pantalla: boolean;
  notificacion_telegram: boolean;
  registrar: boolean;
  tipo: string;
  prioridad: number;
  activo: boolean;
}
