import { Component } from '@angular/core';
import apps from './biogreen-apps.json';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-inicializador-apps',
  templateUrl: './inicializador-apps.component.html',
  styleUrls: ['./inicializador-apps.component.scss']
})
export class InicializadorAppsComponent {
  listApps;
  userApps: string[] = [];


  constructor(
    private msalInstance: MsalService
  ) {
    const { extension_enabled_apps } = this.msalInstance.instance.getActiveAccount().idTokenClaims;
    if (extension_enabled_apps !== '') {
      this.userApps = String(extension_enabled_apps).split('|');
      this.listApps = apps.filter(app => this.userApps.includes(app.className));
    }
  }

  /**
   * Redirige a la url de la plataforma
   * @param url url de la plataforma
   */
  goToLink(url: string) {
    window.location.href = url;
  }
}
