import { Color } from "./color.model";

export interface VehicleDetection extends Omit<Color, 'id'> {
    id_deteccion: number;
    nombre_deteccion: string; 
    descripcion: string;
}

export enum EnumVehicleDetection {
    id_deteccion = 'id_deteccion',
    nombre_deteccion = 'nombre_deteccion',
    es = 'es',
    en = 'en',
    fr = 'fr',
    ca = 'ca',
    descripcion = 'descripcion'
}