import { MatTableDataSource } from "@angular/material/table";

export interface precio {
    IdInstalacion: Number;
    year: Number;
    PreciokWh: Number;
  }
export interface precioTmp {
    IdInstalacion: Number,
    listaAgregar: { IdInstalacion: Number, year: Number,PreciokWh: String }[],
    listaEliminar: { IdInstalacion: Number, year: Number,PreciokWh: String }[],
    listaModificar: { IdInstalacion: Number, year: Number,PreciokWh: String }[],
    PreciosTmp: MatTableDataSource<precio>,
    PreciosAgregar: { IdInstalacion: Number, year: Number,PreciokWh: String }[],
    PreciosEliminar: { IdInstalacion: Number, year: Number,PreciokWh: String }[],
    PreciosModificar: { IdInstalacion: Number, year: Number,PreciokWh: String }[],
    estadoDialogo:String
  }