import {EventEmitter, Injectable, Output} from '@angular/core';
import { io } from "socket.io-client";
import {configuracion} from '../../../configuracion';
import {FsessionService} from '../session/fsession.service';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    public socket: any = false;
    public socketList = {};
    @Output() AbrirDialogo: EventEmitter<boolean> = new EventEmitter();
    constructor(private fse: FsessionService) {
    }

    testSocket(namespace = '') {
        const host = namespace !== '' ? configuracion.ws_Stream + '/' + namespace : configuracion.ws_Stream;
        return io(host, {forceNew: true});
    }

    DialogoVideo(valor:boolean){
        this.AbrirDialogo.emit(valor)
    }
    setupSocketConnection(namespace, query = {}) {
        return io(`${configuracion.ws_Stream}/${namespace}`, {
            query
        });
    }

    setupSocket() {

        if (!this.socket) {
            this.socket = io(`${configuracion.ws_Stream}`, {
                    query: {
                        token: this.fse.optenerValor('dato1')
                    },
                    forceNew: true,
                    reconnection: true,
                },
            );
        }

        return this.socket;
    }

    reconnect(token) {
        this.socket.on('reconnect_attempt', () => {
            this.socket.io.opts.query = {
                token
            };
        });
    }

    addNewSocket(namespace) {
        this.socketList[namespace] = this.setupSocketConnection(namespace, {
            token: this.fse.optenerValor('dato1')
        });
        
        return this.socketList[namespace];
    }

    stopSocket(namespace) {
        this.socketList[namespace].disconnect();
        this.socketList[namespace].off('data');
    }

    getSocket(namespace) {
        return this.socketList[namespace];
    }

    getSocketList() {
        return this.socketList;
    }
}
