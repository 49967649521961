import {NgModule} from '@angular/core';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService, MsalGuard, MsalGuardConfiguration,
    MsalInterceptor, MsalInterceptorConfiguration,
    MsalModule, MsalService
} from '@azure/msal-angular';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication} from '@azure/msal-browser';
import {environment} from '../../../environments/environment';
import packageJson from '../../../../package.json';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; 

/**
 * Validar info dada por azure
 * @param logLevel niveles de log
 * @param message Mensajes de azure
 */
export function loggerCallback() {
    if(environment.production){
    }
}

/**
 * Configuracion de azure para conectarse
 * @returns 
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.msalConfig.auth.clientId,
            authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
            redirectUri: `${environment.frontend_url}/auth`,
            postLogoutRedirectUri: `${environment.frontend_url}/`,
            knownAuthorities: [environment.b2cPolicies.authorityDomain],
        },
        cache: {
            cacheLocation: BrowserCacheLocation.SessionStorage,
            storeAuthStateInCookie: isIE,
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Error
            },
            tokenRenewalOffsetSeconds: 30
        }
    });
}

/**
 * Interceptor de azure
 * @returns 
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);
    return {
        interactionType: environment.msalConfig.interactionType,
        protectedResourceMap
    };
}

/**
 * Guardar configuracion de azure
 * @returns 
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    const saved_ui_locales = localStorage.getItem('ui_locales') ?? 'es';

    return {
        interactionType: environment.msalConfig.interactionType,
        authRequest: {
            scopes: [...environment.apiConfig.scopes],
            extraQueryParameters: { version: packageJson.version, ui_locales: saved_ui_locales }
        },
        loginFailedRoute: '/login-failed'
    };
}

@NgModule({
    imports: [
        MsalModule
    ],
    exports: [
        MsalModule
    ],
    declarations: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
})
export class AuthModule {
}
