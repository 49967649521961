import { Component, OnInit, Inject, ViewChild, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatTableDataSource } from "@angular/material/table";

import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { DatabaseService } from "src/app/servicios/database/database.service";
import { MunicipioG } from "src/app/models";
import { UsuarioTabla2 } from "src/app/models/usuario";
import { MessageService } from "src/app/servicios/message/message.service";
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from "saturn-datepicker";
import { FsessionService } from "src/app/servicios/session/fsession.service";
import { LoaderService } from "src/app/component/loader-spinner/loader.service";

export const miformato = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-modificar-municipio",
  templateUrl: "./modificar-municipio.component.html",
  styleUrls: ["./modificar-municipio.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: miformato },
  ],
})
export class ModificarMunicipioComponent implements OnInit, OnDestroy {
  modificar = "true";
  milisegundos = 1000;
  date = new FormControl(moment());
  date2 = new FormControl(moment());
  usuarios = new MatTableDataSource<UsuarioTabla2>();
  usuariosActuales: UsuarioTabla2[] = new Array();
  displayedCol: string[] = ["Nombre"];

  dataOiriginal: any;
  stateCtrl = new FormControl();
  stateCtrlAc = new FormControl();
  variables;
  keyTraslate: string[] = [
    "instalacionAgregadaPreviamente",
    "sesionCaducada",
    "nombre",
    "provincia",
    "Latitud",
    "Longitud",
    "tablaAstronomica",
    "faltanSiguientesDatos",
    "error",
    "cerrar",
    "haOcurridoUnError",
    "notificacionEnviada",
    "verificaContactoTelefonoMunicipio"
  ];
  traductor: Subscription = new Subscription();
  usuariosNuevos = null;
  tablasAstronomicas;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("auto", { static: false }) autocom: MatAutocomplete;

  zonaHoraria: any;
  searchControl = new FormControl('');

  constructor(
    private traslate: TranslateService,
    private fse: FsessionService,
    public router: Router,
    public databaseService: DatabaseService,
    public dialogRef: MatDialogRef<ModificarMunicipioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MunicipioG,
    public dialog: MatDialog,
    public messageService: MessageService,
    private loaderService: LoaderService
  ) {
    this.traslate.get(this.keyTraslate).subscribe((res) => {
      this.variables = res;
    });
    this.fse.getSubscribeLang().subscribe((lang) => {
      this.traslate.use(lang);
      this.traslate.get(this.keyTraslate).subscribe((res) => {
        this.variables = res;
      });
    });

    this.dataOiriginal = {
      IdMunicipio: data.IdMunicipio,
      Nombre: data.Nombre,
      Direccion: data.Direccion,
      Provincia: data.Provincia,
      CodPostal: data.CodPostal,
      Poblacion: data.Poblacion,
      Contacto: data.Contacto,
      Contacto2: data.Contacto2,
      Contacto3: data.Contacto3,
      Email: data.Email,
      Telefono: data.Telefono,
      Telefono2: data.Telefono2,
      Telefono3: data.Telefono3,
      Fechainstalacion: data.Fechainstalacion,
      FechaInstalacionAcometida: data.FechaInstalacionAcometida,
      CUPS: data.CUPS,
      IdTarifa: data.IdTarifa,
      Latitud: data.Latitud,
      Longitud: data.Longitud,
      Eliminado: Number(data.Eliminado),
      listaAgregar: data.listaAgregar,
      listaEliminar: data.listaEliminar,
      UsuariosTmp: data.UsuariosTmp,
      modoModificar: true,
    };

    this.stateCtrl.valueChanges.subscribe((val) => {
      if (val.toString().length >= 3) {
        setTimeout(() => {
        }, this.milisegundos);
      } else {
        this.usuariosNuevos = null;
      }
    });
    this.stateCtrlAc.valueChanges.subscribe(() => {
      this.validaNumericosFrm(this.stateCtrlAc);
    });

    this.getZonaHoraria('');

    this.searchControl.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe((filterValue: string) => {
      this.getZonaHoraria(filterValue);
    });
  }

  /**
   * Verifica si se ha modificado el dato de alguna variable
   * @returns Boolean
   */
  validateNewChanges(): boolean {
    var validar = false;

    if (this.dataOiriginal.IdMunicipio != this.data.IdMunicipio) {
      validar = true;
    }
    if (this.dataOiriginal.Nombre != this.data.Nombre) {
      validar = true;
    }
    if (this.dataOiriginal.Direccion != this.data.Direccion) {
      validar = true;
    }
    if (this.dataOiriginal.Provincia != this.data.Provincia) {
      validar = true;
    }
    if (this.dataOiriginal.CodPostal != this.data.CodPostal) {
      validar = true;
    }
    if (this.dataOiriginal.Poblacion != this.data.Poblacion) {
      validar = true;
    }
    if (this.dataOiriginal.Contacto != this.data.Contacto) {
      validar = true;
    }
    if (this.dataOiriginal.Contacto2 != this.data.Contacto2) {
      validar = true;
    }
    if (this.dataOiriginal.Email != this.data.Email) {
      validar = true;
    }
    if (this.dataOiriginal.Telefono != this.data.Telefono) {
      validar = true;
    }
    if (this.dataOiriginal.Telefono2 != this.data.Telefono2) {
      validar = true;
    }
    if (this.dataOiriginal.Fechainstalacion != this.data.Fechainstalacion) {
      validar = true;
    }
    if (
      this.dataOiriginal.FechaInstalacionAcometida !=
      this.data.FechaInstalacionAcometida
    ) {
      validar = true;
    }
    if (this.dataOiriginal.CUPS != this.data.CUPS) {
      validar = true;
    }
    if (this.dataOiriginal.IdTarifa != this.data.IdTarifa) {
      validar = true;
    }
    if (this.dataOiriginal.Latitud != this.data.Latitud) {
      validar = true;
    }
    if (this.dataOiriginal.Longitud != this.data.Longitud) {
      validar = true;
    }
    if (this.dataOiriginal.Eliminado != Number(this.data.Eliminado)) {
      validar = true;
    }

    if (this.data.listaAgregar.length > 0) {
      validar = true;
    }
    if (this.data.listaEliminar.length > 0) {
      validar = true;
    }
    if (this.dataOiriginal.UsuariosTmp != this.data.UsuariosTmp) {
      validar = true;
    }
    if (this.dataOiriginal.modoModificar != true) {
      validar = true;
    }

    return validar;
  }

  /**
   * Verifica si el valor del FormControl es numérico
   * @param valor FormCOntrol
   * @returns Boolean
   */
  validaNumericosFrm(valor: FormControl) {
    if (valor.value != null) {
      var valortmp: String = "" + valor.value;
      if (!isNaN(Number(valortmp.replace(",", ".")))) {
        return true;
      } else {
        valor.setValue("");
      }
    }
  }

  /**
   * Retorna el nombre del objeto de usuario para Selects
   * @param usuario Objeto de Usuario
   * @returns Nombre
   */
  public displayFn(usuario: UsuarioTabla2) {
    if (usuario != null) {
      return usuario && usuario.Nombre ? usuario.Nombre : "";
    }
  }

  ngOnInit() {

    this.date = new FormControl(moment(this.data.Fechainstalacion, 'DD/MM/YYYY'));
    this.date2 = new FormControl(moment(this.data.FechaInstalacionAcometida, 'DD/MM/YYYY'));

    this.TraerUsuarios();

    if (this.data["Eliminado"]) {
      this.data["Eliminado"] = 1;
    } else {
      this.data["Eliminado"] = 0;
    }
    setTimeout(() => {
      if (this.data.modoModificar) {
        this.modificar = "false";
        this.UsuariosModificar();
      }
    }, 1000);
  }

  /**
   * Crea el DataSource de usuario asignados al municipio
   */
  UsuariosModificar() {
    this.usuarios.data = [];
    this.usuarios = new MatTableDataSource(this.data.UsuariosTmp.data);
  }

  /**
   * Cierra el diálogo y restaura los datos
   */
  onNoClick(): void {
    this.data.UsuariosTmp = this.usuarios;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy(): void {
    this.traductor.unsubscribe();
    var val = this.validateNewChanges();

    if (!val) {
      this.data.modoModificar = false;
    }
    this.data.Fechainstalacion = this.date.value.toString();
    this.data.FechaInstalacionAcometida = this.date2.value.toString();
    this.data.UsuariosTmp = this.usuarios;
    this.dialogRef.close(this.data);
  }

  modoModificar() {
    this.modificar = "false";
    this.data.modoModificar = true;
  }

  /**
   * Desactiva el modo modificar del formulario
   * @param remplazarDatos 
   */
  QuitarModoModificar(remplazarDatos = true) {
    if (remplazarDatos) {
      this.data = this.dataOiriginal;
    }
    this.TraerUsuarios();
    this.modificar = "true";
    this.data.modoModificar = false;
  }

  /**
   * Modificar informacion de municipio
   */
  modificarInfo() {
    if (this.data != undefined) {
      var mensaje = "";

      if (this.data.Nombre != undefined) {
        if (this.data.Nombre.toString().trim() == "") {
          mensaje += `\n-${this.variables.nombre.toUpperCase()}`;
        }
      } else {
        mensaje += `\n-${this.variables.nombre.toUpperCase()}`;
      }

      if (this.data.Provincia != undefined) {
        if (this.data.Provincia.toString().trim() == "") {
          mensaje += `\n-${this.variables.provincia.toUpperCase()}`;
        }
      } else {
        mensaje += `\n-${this.variables.provincia.toUpperCase()}`;
      }

      if (this.data.Latitud != undefined) {
        if (this.data.Latitud.toString().trim() == "") {
          mensaje += `\n-${this.variables.Latitud.toUpperCase()}`;
        }
      } else {
        mensaje += `\n-${this.variables.Latitud.toUpperCase()}`;
      }
      if (this.data.Longitud != undefined) {
        if (this.data.Longitud.toString().trim() == "") {
          mensaje += `\n-${this.variables.Longitud.toUpperCase()}`;
        }
      } else {
        mensaje += `\n-${this.variables.Longitud.toUpperCase()}`;
      }

      if (this.data.IdTarifa == undefined) {
        this.data.IdTarifa = "0";
      }

      if (this.data.Direccion == undefined) {
        this.data.Direccion = null;
      }
      if (this.data.CodPostal == undefined) {
        this.data.CodPostal = null;
      }
      if (this.data.Poblacion == undefined) {
        this.data.Poblacion = null;
      }
      if (this.data.Contacto == undefined) {
        this.data.Contacto = null;
      }
      if (this.data.Email == undefined) {
        this.data.Email = null;
      }
      if (this.data.Telefono == undefined) {
        this.data.Telefono = null;
      }
      if (this.data.CUPS == undefined) {
        this.data.CUPS = null;
      }

      if (this.data.Fechainstalacion == undefined) {
        this.data.Fechainstalacion = null;
      } else {
        var fechaI = new Date(this.date.value.toString());
        this.data.Fechainstalacion =
          fechaI.getUTCMonth() +
          1 +
          "-" +
          fechaI.getDate() +
          "-" +
          fechaI.getUTCFullYear() +
          " " +
          fechaI.getHours() +
          ":" +
          fechaI.getMinutes() +
          ":00";
      }

      if (this.data.FechaInstalacionAcometida == undefined) {
        this.data.FechaInstalacionAcometida = null;
      } else {
        var fechaIA = new Date(this.date2.value.toString());
        this.data.FechaInstalacionAcometida =
          fechaIA.getUTCMonth() +
          1 +
          "-" +
          fechaIA.getDate() +
          "-" +
          fechaIA.getUTCFullYear() +
          " " +
          fechaIA.getHours() +
          ":" +
          fechaIA.getMinutes() +
          ":00";
      }

      var listaEliminarFinal = "";
      var listaAgregarFinal = "";

      for (let index = 0; index < this.data.listaAgregar.length; index++) {
        if (this.data.listaAgregar[index].IdUsuario.toString().trim() != "") {
          if (index + 1 == this.data.listaAgregar.length) {
            listaAgregarFinal += this.data.listaAgregar[index].IdUsuario;
          } else {
            listaAgregarFinal += this.data.listaAgregar[index].IdUsuario + ",";
          }
        }
      }
      this.data.usuariosAgregar = listaAgregarFinal;

      for (let index = 0; index < this.data.listaEliminar.length; index++) {
        if (this.data.listaEliminar[index].IdUsuario.toString().trim() != "") {
          if (index + 1 == this.data.listaEliminar.length) {
            listaEliminarFinal += this.data.listaEliminar[index].IdUsuario;
          } else {
            listaEliminarFinal +=
              this.data.listaEliminar[index].IdUsuario + ",";
          }
        }
      }
      this.data.usuariosEliminar = listaEliminarFinal;

      if (mensaje.toString().trim() == "") {
        this.loaderService.showSpinner();
        this.data.UsuariosTmp = null;
        this.modificar = "true";
        this.data.Eliminado = Number(this.data.Eliminado);
        this.databaseService.ModificarMunicipio(this.data).subscribe(
          (data) => {
            this.loaderService.hideSpinner();
            if (data["mensaje"].toString().indexOf("ACTUALIZADO") >= 0) {
              this.QuitarModoModificar(false);
              this.messageService.SwalMessage("success", data["mensaje"]);
            } else {
              this.messageService.SwalMessage("error", data["mensaje"]);
            }
          },
          (err) => {
            this.loaderService.hideSpinner();
            this.modificar = "false";
            this.QuitarModoModificar();
            this.dialogRef.close(this.data);
            this.messageService.SwalMessage("error", err);
          }
        );
      } else {
        this.messageService.SwalMessage(
          "error",
          this.variables.faltanSiguientesDatos + mensaje
        );
      }
    }
  }

  /** 
   * Traer usuarios por el municipio 
   */
  TraerUsuarios() {
    this.databaseService
      .TraerUsuariosPorMunicipio(this.data.IdMunicipio)
      .subscribe(
        (usuario: UsuarioTabla2[]) => {
          usuario.map((us) => {
            this.usuariosActuales.push({
              IdUsuario: us.IdUsuario,
              Nombre: us.Nombre,
            });
          });

          this.usuarios.data = usuario;
          this.usuarios.sort = this.sort;
        },
        () => {
          this.messageService.SwalMessage(
            "error",
            this.variables.error
          )
        }
      );
  }

  /**
 * Limpiar filtro
 * @param valor true | false
 */
  limpiarinput1(valor: boolean) {
    this.loaderService.showSpinner(true);
    if (valor) {
      this.getZonaHoraria('');
    }
    this.loaderService.hideSpinner();
  }

  /**
   * Obtener zonas horarias
   * @param filterValue 
   */
  getZonaHoraria(filterValue: string) {
    if (filterValue === '' || filterValue.length >= 3) {
      this.loaderService.showSpinner(true);
      this.databaseService.getZonaHoraria(filterValue).subscribe((res) => {
        this.zonaHoraria = res;
        this.loaderService.hideSpinner();
      });
    }
  }

  /** Envia notificacion de prueba */
  sendNotification() {

    if ( this.data?.Contacto.trim() !== '' && ( this.data.Telefono?.trim() !== '' || this.data.Telefono2?.trim() !== '' ) ) {

      this.loaderService.showSpinner(true);
      this.databaseService.sendTestNotification( 'telegram', {
        last_name: '',
        first_name: this.data.Contacto,
        phone: this.data.Telefono?.trim() !== '' ? this.data.Telefono : this.data.Telefono2,
        level: 'info'
      })
      .subscribe({
        next: (response) => {
  
          this.loaderService.showSpinner(false);
          if ( response.mensaje === 'OK' && response.body && response.body.trim() !== '' ) {
            this.messageService.snackBarMessage(this.variables.notificacionEnviada, this.variables.cerrar, {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            });
            return;
          }
          this.messageService.SwalMessage('error', this.variables.haOcurridoUnError);
  
        },
        error: (err) => {
          this.loaderService.showSpinner(false);
          this.traslate.get(err.error.traslateError).subscribe(message => this.messageService.SwalMessage('error', message));
        }
      });

    } else {
      this.messageService.SwalMessage( 'info', this.variables.verificaContactoTelefonoMunicipio );
    }


  }
  
}
