export interface calleG {
    IdCalle:any,
    Nombre:any,
    IdMunicipio:any,
    Municipio:any,
    FechaCreacion:any,
    FechaModificacion:any,
    Eliminado:any,
    totalRegistros?: number
}

export interface calleSel {
    IdCalle:any,
    Nombre:any
}

export interface CalleFormulario {
    IdCalle: string,
    Nombre: string,
    IdMunicipio: string,
    Eliminado: any,
    Entorno: string,
    Titulo: string,
    estadoDialogo: string,
    Modificar: boolean,
    DatosOriginales: any
}
