export interface EventosCam {
    id: number;
    archivo: string;
    error: boolean;
    error_descripcion: string;
    id_camara: string;
    Fecha_imagen: string;
    Fecha_imagen_utc: string;
    Fecha_imagen_local: string;
    deteccion: string;
    estado_imagen: StatusImage;
}
  
export enum StatusImage {
    'PENDING' = 0,
    'INPROGRESS' = 1,
    'UPLOADED' = 2,
    'ERROR' = 3
}

export enum ConsumptionSaving {
    'DISABLED' = 0,
    'ENABLED' = 1
}