export interface Color {
    id: number;
    en: string;
    es: string;
    fr: string;
    ca: string;
}

export enum EnumColor {
    id = 'id',
    es = 'es',
    en = 'en',
    fr = 'fr',
    ca = 'ca'
}