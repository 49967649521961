import { InteractionType } from "@azure/msal-browser";

const {
  NG_APP_SERVIDOR_API,
  NG_APP_SERVIDOR_API_MODEM,
  NG_APP_CLAVEENCRIPTACION,
  NG_APP_APIKEY,
  NG_APP_PARTIALCONTENTSIZE,
  NG_APP_RUTAPHP,
  NG_APP_SERVIDOR_IMG,
  NG_APP_SERVIDOR_STREAM,
  NG_APP_WS_STREAM,
  NG_APP_APIIPPUBLICA,
  NG_APP_TIME_INACTIVITY_MS,
  NG_APP_EXPIRATION_PASSWORD,
  NG_APP_KEY_CIFRADO_RESPUESTA,
  NG_APP_FRONTEND_URL,
  NG_APP_B2C_CLIENTID_PRODUCTION,
  NG_APP_B2C_SCOPES_PRODUCTION,
  NG_APP_B2C_URI_PRODUCTION,
  NG_APP_B2C_SIGNUPSIGNINNAMES_PRODUCTION,
  NG_APP_B2C_EDITPROFILENAMES_PRODUCTION,
  NG_APP_B2C_SIGNUPSIGNINAUTHORITIES_PRODUCTION,
  NG_APP_B2C_EDITPROFILEAUTHORITIES_PRODUCTION,
  NG_APP_B2C_CHANGEPASSWORDAUTHORITIES_PRODUCTION,
  NG_APP_B2C_AUTHORITYDOMAIN_PRODUCTION,
  NG_APP_DOMAIN_ALLOWED_NUMBER_ONE,
  NG_APP_DOMAIN_ALLOWED_NUMBER_TWO,
  NG_APP_DOMAIN_ALLOWED_NUMBER_THREE,
  NG_APP_DOMAIN_ALLOWED_NUMBER_FOUR,
  NG_APP_DOMAIN_ALLOWED_NUMBER_FIVE,
  NG_APP_MAX_NUMERO_MATRICULA,
  NG_APP_PRODUCTION
  // Environments que se utlizan para ejecutar el b2c a traves del ngrok
  /* 
  NG_APP_B2C_CLIENTID_LOCAL,
  NG_APP_B2C_SCOPES_LOCAL,
  NG_APP_B2C_SIGNUPSIGNINNAMES_LOCAL,
  NG_APP_B2C_SIGNUPSIGNINAUTHORITIES_LOCAL 
  */
} = import.meta.env;

export const environment = {
  production: NG_APP_PRODUCTION === 'true' ? true : false,
  whitelistedDomains: [ 
    NG_APP_DOMAIN_ALLOWED_NUMBER_ONE,
    NG_APP_DOMAIN_ALLOWED_NUMBER_TWO,
    NG_APP_DOMAIN_ALLOWED_NUMBER_THREE,
    NG_APP_DOMAIN_ALLOWED_NUMBER_FOUR,
    NG_APP_DOMAIN_ALLOWED_NUMBER_FIVE 
  ],
  servidor_api:           NG_APP_SERVIDOR_API,
  servidor_api_Modem:     NG_APP_SERVIDOR_API_MODEM,
  claveEncriptacion:      NG_APP_CLAVEENCRIPTACION,
  ApiKey:                 NG_APP_APIKEY,
  partialContentSize:     parseInt(NG_APP_PARTIALCONTENTSIZE),
  rutaPhp:                NG_APP_RUTAPHP,
  servidor_Img:           NG_APP_SERVIDOR_IMG,
  servidor_Stream:        NG_APP_SERVIDOR_STREAM,
  ws_Stream:              NG_APP_WS_STREAM,
  apiIpPublica:           NG_APP_APIIPPUBLICA,
  time_inactivity_ms:     parseInt(NG_APP_TIME_INACTIVITY_MS),
  expiration_password :   parseInt(NG_APP_EXPIRATION_PASSWORD),
  key_cifrado_respuesta:  NG_APP_KEY_CIFRADO_RESPUESTA,
  frontend_url:           NG_APP_FRONTEND_URL,
  max_numero_matricula:   parseInt(NG_APP_MAX_NUMERO_MATRICULA),

  /** Configuraciones MSAL (Microsoft Authentication Library)  */
  msalConfig: {
    auth: {
      clientId: NG_APP_B2C_CLIENTID_PRODUCTION
      // clientId: NG_APP_B2C_CLIENTID_LOCAL // prueba
    },
    interactionType: InteractionType.Redirect as InteractionType.Redirect | InteractionType.Popup,
  },
  apiConfig: {
    scopes: [
      NG_APP_B2C_SCOPES_PRODUCTION
      // NG_APP_B2C_SCOPES_LOCAL // prueba
    ], // Permisos de API
    uri: NG_APP_B2C_URI_PRODUCTION // Url del servicio a proteger
  },
  b2cPolicies: {
    // FLujos de Usuario
    names: {
      signUpSignIn: NG_APP_B2C_SIGNUPSIGNINNAMES_PRODUCTION,
      // signUpSignIn: NG_APP_B2C_SIGNUPSIGNINNAMES_LOCAL // prueba
      editProfile: NG_APP_B2C_EDITPROFILENAMES_PRODUCTION
    },
    authorities: {
      signUpSignIn: {
        authority:
          NG_APP_B2C_SIGNUPSIGNINAUTHORITIES_PRODUCTION
          // NG_APP_B2C_SIGNUPSIGNINAUTHORITIES_LOCAL // prueba
      },
      editProfile: {
        authority:
          NG_APP_B2C_EDITPROFILEAUTHORITIES_PRODUCTION
      },
      changePassword: {
        authority:
          NG_APP_B2C_CHANGEPASSWORDAUTHORITIES_PRODUCTION 
      }
    },
    authorityDomain: NG_APP_B2C_AUTHORITYDOMAIN_PRODUCTION
  }
};