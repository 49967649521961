import { SwUpdate } from "@angular/service-worker";
import { Injectable } from "@angular/core";
import { interval } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class UpdateService {
  wordTraslate : string[] = ['nuevaVersion','cancelar','Boton_Actualizar','ultVersion','versionDescargada','recargaActualizacion','aceptar','error','errorDescarga'];
  firstSearch: boolean = false;
  constructor( private updates: SwUpdate, private _snackBar: MatSnackBar, private translate: TranslateService,) { 
    
  }

  /**
   * Este método cumple varias funciones
   * * Verifica si el Service Worker está activo
   * * Verifica la primera vez si hay alguna actualización, si no, lo hace cada `2` horas.
   * * De haber una actualización muestra una alerta
   */
  checkUpdates() {
    
    this.translate.get(this.wordTraslate).subscribe(wordTraslate => {
      
      if (this.updates.isEnabled) {
        this.updates.versionUpdates.subscribe((event) => {
          switch (event.type) {
            case "VERSION_DETECTED":
              Swal.fire({icon:'success',title: wordTraslate.nuevaVersion,allowEscapeKey: false, allowOutsideClick: false,showConfirmButton: false})
              break;
            case "VERSION_READY":
              const nuevaVersion = event.latestVersion.hash;
              if( Swal.isVisible){ Swal.close()}
              Swal.fire({
                icon: "success",
                title: wordTraslate.versionDescargada,
                html: `<p>${wordTraslate.recargaActualizacion} </p>`,
                customClass: "font-size",
                confirmButtonText: wordTraslate.Boton_Actualizar,
                showCancelButton: false,
                cancelButtonText: wordTraslate.cancelar,
                confirmButtonColor: "#fb5858",
                allowEscapeKey: false,
                allowOutsideClick: false,
                preConfirm() {
                  window.location.reload();
                },
                footer: `<small>${nuevaVersion}</small>`
              });
              break;
            case "VERSION_INSTALLATION_FAILED":
              if( Swal.isVisible){ Swal.close()}
              Swal.fire({
                icon: "error",
                title: wordTraslate.error,
                html: `
                  <p>${wordTraslate.errorDescarga} </p>
                  <p><b>windows:</b> Ctrl + F5</p>
                  <p><b>OS X:</b> Option + ⌘ Command + E, ⌘ Command + R  </p>  
                `,
                customClass: "font-size",
                confirmButtonText: wordTraslate.aceptar,
                confirmButtonColor: "#fb5858",
                allowEscapeKey: false,
                allowOutsideClick: false
              });
              break;
            default:
              if( this.firstSearch == false){
                this.firstSearch = true;
                const snackBar = this.openSnackbar(wordTraslate.ultVersion);
                snackBar._dismissAfter(3000);
              }
              
              break;
          }
        });
        const everySixHours$ = interval(2 * 60 * 60 * 1000);
        everySixHours$.subscribe(async () => {
          try {
            await this.checkForUpdate();
          } catch (err) {
            console.error('Failed to check for updates:', err);
          }
        })
      } else {
      }
    });
    
    
  }

  /**
   * Verifica bajo demanda si hay una actualización disponible
   */
  async checkForUpdate() {
  }

  /**
   * Abre un snackbar para mostrar
   * @param message mensaje a mostrar
   */
  openSnackbar(message: string) {
    this._snackBar.dismiss();
    return this._snackBar.open(message, null, {horizontalPosition: 'start'});
  }
}
