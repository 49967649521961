import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import moment from "moment";
import { DatabaseService } from "src/app/servicios/database/database.service";

@Component({
  selector: "app-subir-manuales",
  templateUrl: "./subir-manuales.component.html",
  styleUrls: ["./subir-manuales.component.scss"],
})
export class SubirManualesComponent implements OnInit {
  public formControlEsp = new FormControl("");
  public formControlFr = new FormControl("");
  public formControlEng = new FormControl("");

  public files = new FormData();

  constructor(private databaseService: DatabaseService, private dialogRef: MatDialogRef<SubirManualesComponent>) {}

  ngOnInit(): void {
    this.files = new FormData();
  }

  onSubmit(event: any, lang: "es" | "fr" | "en") {
    const file = event.target.files[0];
    this.files.append(lang, file);

    switch (lang) {
      case "es":
        this.formControlEsp.setValue(file.name);
        break;
      case "fr":
        this.formControlFr.setValue(file.name);
        break;
      case "en":
        this.formControlEng.setValue(file.name);
        break;
      default:
        break;
    }
  }

  onConfirm() {
    const fechaActual = moment().format('yyyyMMDDhhmmss');
    this.files.append('filename', `${fechaActual}`);
    this.databaseService.subirManuales(this.files).subscribe(() => {
      this.files = new FormData();
      this.dialogRef.close();
    });
  }
}
