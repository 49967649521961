import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FsessionService } from 'src/app/servicios/session/fsession.service';

@Injectable({
  providedIn: 'root'
})
export class HasRoleGuard implements CanLoad {
  constructor( private fse: FsessionService, private router: Router){}

  /**
   * Funcion para implementar un canLoad en las rutas
   * @param route Ruta a la que se le efectua el canload
   * @returns 
   */
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    let modulos = this.fse.optenerValor('paquetes');
    if(modulos){
      modulos = modulos.split(',');

      let hasAccess = modulos.includes(route.data.moduleId);
      if (!hasAccess) {
        this.router.navigate(['/']);
      }
      return hasAccess;
    }
    this.router.navigateByUrl('/');
    return false;
  }

}
