export interface ColumnSchemaTable {
    title: string;
    name: string
}

export interface ActionsSchemaTable {
    title: string;
    name: string;
    buttonColor: buttonColor;
    icon: string;
}

export interface HandleActionSchemaTable {
    name: string;
    pressed: boolean;
    data: any;
}

declare type buttonColor = 'primary' | 'accent' | 'warn' | 'basic';