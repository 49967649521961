import { FormControl } from "@angular/forms";

export interface TranslationsDataSource {
    variable: string;
    es: string;
    en: string;
    ca: string;
    fr: string;
    pv: string;
}

export interface CreateOrModifyTranslationsData {
    translation: TranslationsDataSource;
    esJSON: {[key: string]: string};
    enJSON: {[key: string]: string};
    caJSON: {[key: string]: string};
    frJSON: {[key: string]: string};
    pvJSON: {[key: string]: string};
    action: 'create' | 'modify'
}

export type TranslationForm = {
    [field in keyof TranslationsDataSource]: FormControl<TranslationsDataSource[field] | ''>;
}

export type SupportedLanguages = 'es' | 'en' | 'ca' | 'fr' | 'pv';