import { Component, Input, OnChanges, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ActionsSchemaTable, ColumnSchemaTable, HandleActionSchemaTable } from 'src/app/models';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnChanges {

  sort: MatSort;
  displayColumns: string[];
  columnsSchema: ColumnSchemaTable[];

  @ViewChild(MatSort, { static: false }) set matSort( sort: MatSort ) {
    if (!this.sideServerPagination) {
      this.sort = sort;
      this.dataSource.sort = sort;
    }
  }
  @ViewChild('paginador', {static: false}) paginator: MatPaginator;

  /**
   * Datos de tipo 'MatTableDataSource' que se mostraran en la tabla
   */
  @Input() dataSource: MatTableDataSource<any>;

  /**
   * Determina si la tabla genera eventos de paginación y ordenación para ser procesado en el componente padre
   */
  @Input() sideServerPagination: boolean = false;

  /**
   * Columnas que tendra la tabla de tipo 'ColumnSchemaTable[]'
   */
  @Input() set columnsTable( columns: ColumnSchemaTable[] ) {
    this.columnsSchema = columns;
    this.displayColumns = this.columnsSchema.map( (column) => column.name );
  }

  /**
   * Acciones (botones) que se mostaran en la tabla de tipo 'ActionsSchemaTable[]'
   */
  @Input() actionsTable: ActionsSchemaTable[];

  /**
   * Bandera que identifica si estan cargando los datos
   */
  @Input() loading: boolean;

  /**
   * Número de filas a mostrar en la tabla
   */
  @Input() pageSize: number = 10;

  /**
   * Opciones para cambiar el número de filas para mostrar en la tabla
   */
  @Input() pageSizeOptions: number[] = [5, 10, 15, 20];

  /**
   * Número de registros que mostrará la tabla
   */
  @Input() length: number = 0;

  /**
   * Identifica que accion (boton) fue oprimido y envia al componente padre esa notificacion
   */
  @Output() handleAction = new EventEmitter<HandleActionSchemaTable>();


  constructor() { }

  ngOnChanges(): void {
    if ( this.dataSource ) {
      if (!this.sideServerPagination) {
        this.dataSource.paginator = this.paginator;
      }else {
        
        if (this.paginator) {
          this.paginator.length = this.length;
        }
        
      }
    }
  }

  /**
   * Emite la accion (boton) oprimido al componente padre
   * @param actionName nombre de la accion (boton)
   * @param data datos de la fila en la tabla
   */
  emitHandleAction( actionName: string, data: any ) {
    this.handleAction.emit({
      name: actionName,
      pressed: true,
      data
    });
  }

}
