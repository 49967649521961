export interface TipoSoporteG {
    IdTipo: any,
    Nombre: any
}
export interface TipoSoporteFormulario {
    IdTipo: String,
    Nombre: String,
    Entorno:String,
    Titulo:String,
    estadoDialogo:String,
    Modificar:boolean,
    DatosOriginales:any,
    Descripcion?: string,
    Activo? : boolean
}
    