export interface VehicleCountry {
    id_pais: number;
    nombre_pais_es: string;
    nombre_pais_en: string;
    nombre_pais_fr: string;
    nombre_pais_ca: string;
    nombre_pais_pv: string;
}

export enum EnumVehicleCountry {
    id_pais = 'id_pais',
    nombre_pais_es = 'nombre_pais_es',
    nombre_pais_en = 'nombre_pais_en',
    nombre_pais_fr = 'nombre_pais_fr',
    nombre_pais_ca = 'nombre_pais_ca',
    nombre_pais_pv = 'nombre_pais_pv',
}