import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-acerca-de',
  templateUrl: './acerca-de.component.html',
  styleUrls: ['./acerca-de.component.scss']
})
export class AcercaDeComponent {

  fileUrl = 'https://www.ccn-cert.cni.es/amparo/API/public/certificaciones/3086/download/2077';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

}