import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { HeaderComponent } from './component/header/header.component';
import { HasRoleGuard } from './Guards/hasRole/has-role.guard';
import { LoginFailedComponent } from './component/login-failed/login-failed.component';
import { MsalRedirectComponentGuard } from './Guards/msal-redirect.guard/msal-redirect-component.guard';

const routes: Routes = [

  {
    path: 'auth', 
    component: MsalRedirectComponent, 
    canActivate: [MsalRedirectComponentGuard]
  },

  {
    path: '',
    canActivate: [MsalGuard],
    component: HeaderComponent,
    children: [
      {
        path: 'traffic',
        loadChildren: () => import('./modules/traffic/traffic.module').then(x => x.TrafficModule),
        canLoad: [HasRoleGuard], 
        data: { moduleId: '1' }
      },
      {
        path: 'management',
        loadChildren: () => import('./modules/management/management.module').then(x => x.ManagementModule),
        canLoad: [HasRoleGuard], 
        data: { moduleId: '2' }
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/administrate/administrate.module').then(x => x.AdministrateModule), 
        canLoad: [HasRoleGuard], 
        data: { moduleId: '3' }
      },
      { 
        path: 'telemetry',
        loadChildren: () => import('./modules/telemetry/telemetry.module').then(m => m.TelemetryModule), 
        canLoad: [HasRoleGuard], 
        data: { moduleId: '4' }
      },
      { 
        path: 'solar-charger',
        loadChildren: () => import('./modules/solar-charger/solar-charger.module').then(m => m.SolarChargerModule), 
        canLoad: [HasRoleGuard], 
        data: { moduleId: '5' }
      },
      {
        path: 'weather',
        loadChildren: () => import('./modules/weather/weather.module').then(x => x.WeatherModule), 
        canLoad: [HasRoleGuard], 
        data: { moduleId: '6' }
      },
    ]
  },
  { path: "login-failed", component: LoginFailedComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,  {enableTracing: false})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
