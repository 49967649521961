import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  public isLoading = new BehaviorSubject(false);
  public loadingText = new BehaviorSubject(false);
  constructor() {}

  /**
   * Muestra el spinner de carga
   * @param v
   */
  showSpinner(v = true) {
    this.isLoading.next(v); 
  }

  /**
   * Oculta el spinner de carga
   */
  hideSpinner() {
    this.isLoading.next(false);
  }

  /**
   * Retorna un observable
   * @returns Observable
   */
  isLoading$() {
    return this.isLoading.asObservable()
  }
}
