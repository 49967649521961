export interface Ticket {
  id_ticket: number;
  issue_id_jira: string | null;
  asunto: string;
  estado: EnumStateTicket;
  modulo: string;
  submodulo: string;
  descripcion: string;
  archivo: string | null;
  id_usuario: number;
  id_municipio: number;
  fecha_alta: string;
  fecha_modificacion: string | null;
  nombre_usuario?: string;
  nombre_municipio?: string;
  totalRegistros?: number;
}

export interface ModelStateTicket {
  title: 'open' | 'under_review' | 'closed',
  option: EnumStateTicket
}

export enum EnumStateTicket {
  open = 'abierto',
  under_review = 'en revision',
  closed = 'cerrado'
}

export interface ModelAllowedModules {
  name: EnumAllowedModules
}

export enum EnumAllowedModules {
  traffic = 'Trafico',
  management = 'Gestion',
  admin = 'Administrar',
  telemetry = 'Telemetría',
  'solar-charger' = 'telemetria-cargador-solar',
  weather = 'Clima',
  otros = 'otros'
}