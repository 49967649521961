import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/servicios/database/database.service';
import { ModificarMunicipioComponent, miformato } from '../modificar-municipio/modificar-municipio.component';
import {  MunicipioG } from 'src/app/models';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from 'saturn-datepicker';
import { TranslateService } from '@ngx-translate/core';
import { FsessionService } from 'src/app/servicios/session/fsession.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { nombreUserRol } from '../../models/municipio.model';

@Component({
  selector: 'app-conf-municipio',
  templateUrl: './conf-municipio.component.html',
  styleUrls: ['./conf-municipio.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: miformato }
  ]
})
export class ConfMunicipioComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  modificar: boolean = true;
  stateCtrl = new FormControl();
  stateCtrlAc = new FormControl();
  date = new FormControl(moment());
  date2 = new FormControl(moment());
  variables;
  keyTranslate: string[] = ['sesionCaducada', 'haOcurridoUnError'];
  dataUser = new MatTableDataSource<nombreUserRol>();
  rol: number = Number(this.fse.optenerValor('dato40'));

  displayedCol: string[] = [
    'nombre',
    'rol'
  ];

  constructor(
    private traslate: TranslateService,
    private fse: FsessionService,
    public router: Router, 
    public databaseService: DatabaseService,
    public dialogRef: MatDialogRef<ModificarMunicipioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MunicipioG, 
    public dialog: MatDialog 
  ) {
    this.traslate.get(this.keyTranslate).subscribe(res=> this.variables=res);
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  ngOnInit() {

    this.databaseService.TraerDetalleMunicipio(this.data.IdMunicipio).subscribe((datos: MunicipioG[]) => {
    
    this.data.Eliminado=!datos[0].Eliminado;

      var fechaI = null;
      var fechaIA = null;
      if (datos[0].Fechainstalacion != null) {
        var tmpfe = datos[0].Fechainstalacion.toString().split('/');
        fechaI = new Date(Number(tmpfe[2]), Number(tmpfe[1]) - 1, Number(tmpfe[0]));
      }
      
      if (datos[0].FechaInstalacionAcometida != null) {
        var tmpfe = datos[0].FechaInstalacionAcometida.toString().split('/');
        fechaIA = new Date(Number(tmpfe[2]), Number(tmpfe[1]) - 1, Number(tmpfe[0]));
      }

      this.data.Nombre = datos[0].Nombre;
      this.data.Direccion=datos[0].Direccion;
      this.data.Provincia=datos[0].Provincia;
      this.data.CodPostal=datos[0].CodPostal;
      this.data.Poblacion=datos[0].Poblacion;
      this.data.Contacto=datos[0].Contacto;
      this.data.Email=datos[0].Email;
      this.data.Telefono=datos[0].Telefono;
      this.data.Fechainstalacion=fechaI;
      this.data.FechaInstalacionAcometida=fechaIA;
      this.data.CUPS=datos[0].CUPS;
      this.data.IdTarifa=datos[0].IdTarifa;
      this.data.Latitud=datos[0].Latitud;
      this.data.Longitud=datos[0].Longitud;
    
    }, () => {
      this.mensaje('error', this.variables.haOcurridoUnError);
    });
    
  }

  ngAfterViewInit() {
    this.getNamesUserRolPorMunicipio();
  }

  public mensaje(tipo, Msm) {
    Swal.fire({
      icon: tipo,
      title: Msm,
      showConfirmButton: false,
      timer: 4000
    });
    return;
  }

  /**
   * Funcion para obtener los usuarios que pertenecen a un municipio
   */
  getNamesUserRolPorMunicipio(){
    if(this.rol == 1 || this.rol == 9){
      this.databaseService.getNamesUserRolPorMunicipio(this.data.IdMunicipio).subscribe(res =>{
        this.dataUser.data = res as nombreUserRol[];
        this.dataUser.sort = this.sort;
        this.dataUser.paginator = this.paginator;
      });
    }
  }
}
