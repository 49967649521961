import {Injectable} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class DrawService {

    layers = {};

    public traductor:Subscription=new Subscription();
    keyTraslate: string[] = ["clickEnCaracteristicaParaEliminar","clickDeshacerCambios",
    "arrastrarControlesMarcadoresEditar","editarCapas","noCapasEditar","eliminarCapa",
    "noCapasEliminar","limpiarTodasLasCapas","limpiarTodo","cancelarEditadoDescartarCambios",
    "cancelar","guardarCambios","guardar","suelteRatonTerminarDibujar","clickArrastreDibujarRectangulo",
    "errorBordesNoSePuedenCruzar","clickComenzarDibujarLinea","clickContinuarDibujandoLinea",
    "clickUltimoPuntoLineaMeta","clickDibujarForma","clickContinuarDibujandoForma",
    "clickContinuarDibujandoForma","clickMapaColocarMarcador","clickMapaColocarMarcadorCirculo",
    "radio","clickArrastreDibujarCirculo","dibujarRuta","dibujarPoligono","dibujarRectangulo",
    "dibujarCirculo","dibujarMarcador","dibujarMarcadorCircular","eliminarUltimoPuntoDibujado",
    "eliminarUltimoPunto","finalizarDibujo","finalizar","cancelarDibujo","cancelar","noPermitenIntersecciones"
];
    variables;

    constructor(private traslate: TranslateService) {
 }

    public colorcarVariables(){
        this.traslate.get(this.keyTraslate).subscribe(res=> this.variables=res);
    }

    translateDraw() {
        L.drawLocal = {
            draw: {
                toolbar: {
                    // #TODO: this should be reorganized where actions are nested in actions
                    // ex: actions.undo  or actions.cancel
                    actions: {
                        title: this.variables.cancelarDibujo,
                        text: this.variables.cancelar
                    },
                    finish: {
                        title: this.variables.finalizarDibujo,
                        text: this.variables.finalizar
                    },
                    undo: {
                        title: this.variables.eliminarUltimoPuntoDibujado,
                        text: this.variables.eliminarUltimoPunto
                    },
                    buttons: {
                        polyline: this.variables.dibujarRuta,
                        polygon: this.variables.dibujarPoligono,
                        rectangle: this.variables.dibujarRectangulo,
                        circle: this.variables.dibujarCirculo,
                        marker: this.variables.dibujarMarcador,
                        circlemarker: this.variables.dibujarMarcadorCircular
                    }
                },
                handlers: {
                    circle: {
                        tooltip: {
                            start: this.variables.clickArrastreDibujarCirculo
                        },
                        radius: this.variables.radio
                    },
                    circlemarker: {
                        tooltip: {
                            start: this.variables.clickMapaColocarMarcadorCirculo
                        }
                    },
                    marker: {
                        tooltip: {
                            start: this.variables.clickMapaColocarMarcador
                        }
                    },
                    polygon: {
                        tooltip: {
                            start: this.variables.clickDibujarForma,
                            cont: this.variables.clickContinuarDibujandoForma,
                            end: this.variables.clickContinuarDibujandoForma
                        }
                    },
                    polyline: {
                        error: this.variables.errorBordesNoSePuedenCruzar,
                        tooltip: {
                            start: this.variables.clickComenzarDibujarLinea,
                            cont: this.variables.clickContinuarDibujandoLinea,
                            end: this.variables.clickUltimoPuntoLineaMeta
                        }
                    },
                    rectangle: {
                        tooltip: {
                            start: this.variables.clickArrastreDibujarRectangulo
                        }
                    },
                    simpleshape: {
                        tooltip: {
                            end: this.variables.suelteRatonTerminarDibujar
                        }
                    }
                }
            },
            edit: {
                toolbar: {
                    actions: {
                        save: {
                            title: this.variables.guardarCambios,
                            text: this.variables.guardar
                        },
                        cancel: {
                            title: this.variables.cancelarEditadoDescartarCambios,
                            text: this.variables.cancelar
                        },
                        clearAll: {
                            title: this.variables.limpiarTodasLasCapas,
                            text: this.variables.limpiarTodo
                        }
                    },
                    buttons: {
                        edit: this.variables.editarCapas,
                        editDisabled: this.variables.noCapasEditar,
                        remove: this.variables.eliminarCapa,
                        removeDisabled: this.variables.noCapasEliminar
                    }
                },
                handlers: {
                    edit: {
                        tooltip: {
                            text: this.variables.arrastrarControlesMarcadoresEditar,
                            subtext: this.variables.clickDeshacerCambios
                        }
                    },
                    remove: {
                        tooltip: {
                            text: this.variables.clickEnCaracteristicaParaEliminar
                        }
                    }
                }
            }
        };
    }

    optionsDraw(editableLayers, maxPoint?) {
        return {
            draw: {
                circlemarker: false,
                polygon: {
                    showArea: true,
                    shapeOptions: {
                        color: 'blue'
                    },
                    allowIntersection: false,
                    drawError: {
                        color: 'red',
                        message: this.variables.noPermitenIntersecciones
                    },
                    maxPoints: maxPoint
                },
                polyline: false,
                rectangle: false,
                circle: false,
                marker: false,
            },
            edit: {
                featureGroup: editableLayers,
                remove: true
            }
        };
    }
}
