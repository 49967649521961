export enum Speed {
    '10s' = '10 Seg.',
    '30s' = '30 Seg.',
    '60s' = '60 Seg.',
    'OFF' = 'OFF'
}

export enum TimeIntervalChangeCameras {
    '10s' = 10000,
    '30s' = 30000,
    '60s' = 60000,
    'OFF' = 'OFF'
}

export interface SettingsViewerRotating {
    lastImage: boolean;
    dragGrid: boolean;
    initialDate: string;
    timeRange: number;
}