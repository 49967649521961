export interface NotificationConnection {
    token: string;
    baseUrl: string;
    url: string;
}

export interface NotificationTestBody {
    first_name: string;
    last_name: string;
    phone: string;
    level: string;
}

export interface ResponseNotificationTest {
    mensaje: string;
    body?: string;
}

export interface NotificationPlate {
    titulo: string;
    mensaje: MesssageNotficationPlate;
    origen: string;
    fecha: Date;
    nivel: "warn" | "info";
    id?: number;
    img?: string;
}

interface MesssageNotficationPlate {
    mensaje: string;
    lugar: string;
    matricula: string;
    color: string;
    modelo: string;
    observaciones: string;
}