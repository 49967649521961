import {Injectable, Injector} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { catchError, map, last } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';

import { DatabaseService } from './database/database.service';
import { FsessionService } from './session/fsession.service';
import { configuracion } from 'src/configuracion';
import { SeguimientoService } from './seguimiento/seguimiento.service';
import { environment } from 'src/environments/environment';
import { MessageService } from './message/message.service';
export interface Credendial {
  accessToken: string;
  refreshToken: string;
  expiration: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  private translations = {
    Tiempo_Agotado: null,
    suusuarionoestaautorizado: null
  };
  private translateService: TranslateService;

  activarReportes:boolean=false;
  vS= configuracion.servidor_Stream;
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  whitelistedDomains: string[] = environment.whitelistedDomains;
  redirectInProgress: boolean = false;

  constructor( 
    private ds: DatabaseService, 
    private fse: FsessionService,  
    private seguimiento: SeguimientoService, 
    private router: Router,
    private msalService: MsalService,
    private messageService: MessageService,
    private readonly injector: Injector
  ) {

    setTimeout(async () => {
      this.translateService = this.injector.get(TranslateService);
      await this.getTranslations();
      this.fse.getSubscribeLang().subscribe( async () => await this.getTranslations() );
    }, 0);

  }
  /**
   * Funcion para interceptar todas las peticiones
   * @param request solicitud
   * @param next Envio
   * @returns 
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Verificar si la solicitud proviene de un dominio permitido
    const isAssetsRequest = request.url.startsWith('/assets/') || request.url.startsWith('./assets/');

    // Verifica si la solicitud es de archivos de traducción 
    const isTranslationsFiles = request.url.includes(configuracion.translations_URL);

    if (this.isWhitelistedDomain(request.url) || this.isWhitelistedDomain(configuracion.apiIpPublica) || isAssetsRequest) {

      // omitimos el establecimiento de "headers"
      if ( isTranslationsFiles ) return next.handle(request);

      return next.handle(this.addHeadersToRequest(request)).pipe(
        catchError((error: HttpErrorResponse) => throwError(this.decryptServerResponse<HttpErrorResponse>(error))),
        last(),
        map((response) => this.decryptServerResponse<HttpEvent<HttpResponse<any>>>(response))
      );

    } else {

      // Evitar redireccionamiento múltiple
      if (!this.redirectInProgress) {
        this.redirectInProgress = true;

        // Cerrar sesión y redirigir al inicio de sesión
        this.fse.closeSession();
        this.logoutAndRedirect();
      }
      return new Observable();
    }

  }

  /**
   * redirigiar al login b2c
   */
  logoutAndRedirect(): void {
    this.msalService.logoutRedirect().subscribe(() => {
      this.router.navigate(['login-failed']);
    });
  }

  /**
   * Validad dominios en la whitelist
   * @param url dominio
   * @returns true | false
   */
  isWhitelistedDomain(url: string): boolean {
    return this.whitelistedDomains.some(domain => url.includes(domain));
  }

  /**
   * agregar datos al header en las peticiones
   * @param request solicitud
   * @returns retorna la solicitud
   */
  addHeadersToRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        idMunicipio: CryptoJS.AES.encrypt(this.fse.optenerValor('dato4').toString(), configuracion.claveEncriptacion).toString(),
        id_rol: CryptoJS.AES.encrypt(this.fse.optenerValor('dato40').toString(), configuracion.claveEncriptacion).toString(),
      }
    });
  }

  /**
   * Valida si se debe realizar la solicitud de un nuevo token
   * @param date1 Milisegundos de la fecha 1
   * @param date2 Milisegundos de la fecha 2
   * @returns True si aun es valido, false si se debe actualizar
   */
  isValidToken( date1: number, date2: number ){
    let timeMiliseconds = date2 - date1;
    if( (timeMiliseconds/60000) > 1){
      return true;
    }
    return false;
  }

  /**
   * Funcion que permite generar un reporte
   * @param request HttpRequest
   */
  generarReporte(request:HttpRequest<any>){

    let noEntrar = `${configuracion.servidor_api}/Alerta`;
    let rLogin=`${configuracion.servidor_api}/login`;
    let rDaP=`${configuracion.servidor_api}/AgregarDescargaPendienteEvento`;

    //Asegurarme de que exista un usuario y generar reporte de inicio de sesion
    setTimeout(()=>{

      if(this.fse.optenerValor('dato2')){
        if(request.url==rLogin){ // Succeso de inicio de sesion
          this.ds.crearSuceso('', '', 1);
          this.activarReportes=true;
        
        }else{ 
          this.activarReportes=true
        }
      }
    },3000);

    if(this.activarReportes){

      if(request.url==noEntrar){
          return;
      }else{

        if(request.url==rDaP){

          let d={
            FechaEventoInicio: request.body.FechaEventoInicio,
            FechaEventoFin:request.body.FechaEventoFin,
            FechaEventoInicioUtc:request.body.FechaEventoInicioUtc,
            FechaEventoFinUtc:request.body.FechaEventoFinUtc,
            Municipio:request.body.Municipio,
            Camara:request.body.Camara,
            nombre:request.body.nombre,
            duracion:request.body.duracion
          }

          this.ds.crearSuceso(request.url, JSON.stringify(d), 3).subscribe(()=>{});

        }else{
          if(this.seguimiento.contenedorRutas.includes(request.url)){
            this.ds.crearSuceso(request.url, JSON.stringify(request.body), 3).subscribe(()=>{});
          }
        }

      }
    }      

  }

  /**
   * Función que permite descifrar la información que llega del servidor
   * @param response HttpEvent<T> | HttpErrorResponse
   * @returns HttpEvent<T> que representa todos los tipos posibles de respuesta en este caso solo dos (HttpEvent, HttpErrorResponse)
   */
  decryptServerResponse<T>(response: HttpEvent<T> | HttpErrorResponse): HttpEvent<T> {
    
    if (configuracion.production) {
      const decryptedData: string = CryptoJS.AES.decrypt(
          (response as HttpResponse<T>).body ||
          (response as HttpErrorResponse).error,
          configuracion.key_cifrado_respuesta
      ).toString(CryptoJS.enc.Utf8);
        
      // Si la data está descifrada procedemos 
      if (decryptedData) {
          (response as any).body = JSON.parse(decryptedData);
          if( response instanceof HttpErrorResponse){
            
            switch( response.status ) {
              case 504: 
                this.messageService.SwalMessage("error", this.translations.Tiempo_Agotado);
                return null;
              case 401:
                if ( !response.url.includes('stream2') && !response.url.includes('stream/stop') ) {
                  this.messageService.SwalMessage("error", this.translations.suusuarionoestaautorizado);
                  return null;
                }
              default:
                break;
            }
          }
          return response as HttpEvent<T>;
          
      }
      return response as HttpEvent<T>;
    } else {
      if (response instanceof HttpErrorResponse) {

        switch( response.status ) {

          case 504: 
            this.messageService.SwalMessage("error", this.translations.Tiempo_Agotado);
            return null;
          case 401:
            if ( !response.url.includes('stream2') && !response.url.includes('stream/stop') ) {
              this.messageService.SwalMessage("error", this.translations.suusuarionoestaautorizado);
              return null;
            }
          default:
            break;
        }

      }

      return response as HttpEvent<T>;
    }
    
  }

  /**
   * Realiza las traducciones cada vaz que se invoque
   * @returns {Promise<boolean>}
   */
  getTranslations(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      this.translateService.get(['Tiempo_Agotado', 'suusuarionoestaautorizado'])
      .subscribe((translations) => {
        this.translations = translations;
        resolve(true);
      });

    });

  }

}

export const AuthinterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
};
