export interface GraficosV1 {
    datasource: Object;
    datasource2: Object;
    datasource3: Object;
    datasource4: Object;

    totales: Object;
  }
export interface GraficosV2 {
    datasource: Object;
    datasource2: Object;
    datasource3: Object;
    datasource4: Object;
    datasource5: Object;
    datasource6: Object;

    totales: Object;
  }
export interface GraficosAcometida {
    datasource1: Object;
    datasource2: Object;
    datasource3: Object;
    datasource4: Object;
    datasourceAgrupado: Object;
    datasourceAgrupado2: Object;
    resultadoGrafico: any;
    totales: Object;
  }
export interface GraficosMatricula {
    datasource: Object;
    datasourceVh: Object;
    resultadoGrafico: any;
    totales: Object;
  }
export interface GraficosEfectividad {
    datasource1: Object;
    datasource2: Object;
    datasource3: Object;
    datasource4: Object;
  }
export interface GraficosSolar {
    datasource1: Object;
    datasource2: Object;
    datasource3: Object;
    datasource4: Object;
    totales: Object;
  }
  
export interface TotalesGraficos {
  coches:any;
  nomotor:any;
  bus:any;
  camion:any;
  Total:any;
  }
  
